<template>
  <div>
    <headerMin>
      <div class="hader-top-min-1">
        <i class="fl"></i>
        <div class="fl logo htm1">服务声明</div>
      </div>
    </headerMin>
    <div class="wrap aboutus-content">
      <div class="img">
        <img src="../../assets/image/bg1.jpg" alt="">
      </div>
      <div class="txt">
  
      </div>
    </div>
    <publicBottom> </publicBottom> 
  </div>
</template>
<script>
import headerMin from "@/components/public/public_headerMin.vue";
import publicBottom from "@/components/public/public_bottom.vue";
export default {
  components: {
    headerMin,publicBottom
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>

</style>

