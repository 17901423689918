<template>
  <div>
    <div class="header_min bg1">
      <div class="wrap header_min_box">
        <div class="fl logo" @click="$router.push('/')">
          <img src="../../assets/image/funuo.png" alt />
          孚诺大药房
        </div>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>
@import '../../assets/less/header';
</style>
